.reportdays-title-style {
    color: #eeeeee !important;
    display: inline-block;
    margin-right: 10px !important;
}

.reportdays-value-container-style {
    display: inline-block;
    margin-right: 10px !important;
}

.reportdays-value-style {
    background-color: transparent !important;
    color: #eeeeee !important;
    width: 70px !important;
}

.reportdays-value-change-button-container-style {
    display: inline-block;
}

.reportdays-value-change-button {
    color: green !important;
    cursor: pointer;
}

.reportdays-value-change-button:hover {
    color: rgb(6, 175, 6) !important;
}

.reportdays-value-change-button:active {
    color: rgb(2, 85, 2) !important;
}