.one-hennig-container {
  margin: 6px;
  padding: 15px 10px 15px 15px;
  min-height: 480px;
  border-radius: 8px;
  background: var(--sidebarColor);
}

.one-hennig-container:hover {
  background: linear-gradient(#333333, #222222);
  cursor: pointer;
  box-shadow: 0px 0px 5px #21a300;
}

.one-hennig-container:active {
  background: linear-gradient(#080808, #1d1c1c);
}

.one-hennig-name {
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #eeeeee;
}

.one-hennig-id {
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #eeeeee;
}

.one-hennig-image-style {
  height: 120px;
  margin-bottom: 10px;
  background-color: transparent;
  border: 1px rgb(175, 175, 175) solid;
  border-radius: 5px !important;
}

.one-hennig-image-container-style {
  text-align: center !important;
  /* background-color: white; */
}

.one-hennig-status {
  font-size: 60px;
}

.one-hennig-status-online{
  font-size: 15px;
  color: green;
  font-weight: bold;
  margin-right: 30px;
}

.one-hennig-status-offline{
  font-size: 15px;
  color: red;
  font-weight: bold;
  margin-right: 30px;
}

.one-hennig-delete-button {
  float: right;
  color: #aaaaaa;
  font-size: 24px;
  margin-right: 10px;
  margin-top: -5px;
}

.one-hennig-delete-button:hover {
  color: white;
}
.one-hennig-delete-button:active {
  color: rgb(104, 102, 102);
}

.hennig-locaion-container {
  margin-top: 20px !important;
}

.hennig-one-item-reading-time {
  position: absolute;
  bottom: 25px;
  right: 20px;
  font-size: 13px;
  color: white;
}
