.login-container {
  background-image: url("assets/images/login_back.svg");
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  width: 100%;
  height: 100vh;
  text-align: center;
  background-color: var(--backgroundColor)
}

div.LoginDialog {
  width: 90%;
  max-width: 410px;
  height: 570px;
  margin-top: 7%;
  display: inline-block;
  padding: 40px;
  text-align: center;
  background-color: rgb(36, 37, 37);
}

.whiteText {
  color: #cccccc;
}

.login-page-logo-container {
  text-align: center;
  margin-bottom: 15px;
}
.login-page-logo-image {
  width: 220px !important;
  margin-top: 25px;
  margin-bottom: 30px;
}

.login-page-title {
  color: #fff;
  line-height: 15px;
  font-size: 15px;
}

.login-page-link-text {
  color: white;
  margin-top: 5px;
  font-size: 14px;
}

.login-input {
  background: white;
  color: black;
  width: 100%;
  height: 40px;
  margin-top: 15px !important;
}

.login-button {
  width: 100%;
  height: 40px !important;
  margin-top: 20px;
  cursor: pointer;
  opacity: 0.9;
  transition: 200ms all ease;
  font-weight: bold !important;
  border: #fff solid 1px !important;
}
