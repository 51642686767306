.util-chart-desc {
  font-style: italic;
  color: #ffffff;
}

.util-chart-desc-text {
  color: #ffffff;
}

.util-chart-price {
  color: #ffffff;
  font-size: 30px;
  margin-top: 20px;
  padding-top: 40px;
}

.util-chart-percent {
  background-color: #2ca02c;
  color: #eeeeee;
  border-radius: 30px;
  width: 80px;
  text-align: center;
  padding: 2px;
}

.utilization-dashboard {
  padding: 40px;
}

.util-rect-round-item {
  padding: 20px;
  background-color: var(--sidebarColor);
  height: 250px;
  text-align: left;
}

.util-device-name {
  font-size: 18px;
  color: var(--whiteColor);
  font-weight: bold;
  text-align: center;
}

.util-item-title {
  font-size: 16px;
  color: var(--whiteColor);
  padding-left: 20px;
}

.util-item-name {
  font-size: 16px;
  color: var(--whiteColor);
  text-align: center;
}



.util-item-offline-status {
  color: white;
  font-size: 20px;
  border: 1px solid white;
  padding: 1px 5px;
  width: fit-content;
}

.util-avatar {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.util-detail-header-back {
  background: transparent;
  margin: 0px !important;
  text-align: left;
}

.machine-detail-header-date {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #eeeeee !important;
  text-align: right !important;
}

.machine-detail-header-name {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #eeeeee !important;
  text-align: left !important;
}

.machine-detail-customer-container {
  height: 70px !important;
  background: var(--sidebarColor);
  border-radius: 5px;
  padding: 5px 15px 5px 15px;
  margin-left: 10px;
}

.machine-detail-indicator-container {
  background: var(--sidebarColor);
  border-radius: 5px;
  padding: 5px 15px 5px 15px;
  margin-top: 10px;
}

.machine-detail-img-container {
  background: var(--sidebarColor);
  border-radius: 5px;
  padding: 5px 15px 5px 15px;
  margin-top: 10px;
  margin-left: 10px;
}

.machine-detail-camera-container {
  background: var(--sidebarColor);
  border-radius: 5px;
  padding: 5px 15px 5px 15px;
  margin-top: 5px;
  margin-left: 10px;
}

.machine-detail-utilization-container {
  background: var(--sidebarColor);
  border-radius: 5px;
  padding: 5px 5px 8px 5px;
  margin-top: 10px;
}

.machine-detail-text-container {
  background: var(--sidebarColor);
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
  margin-top: 10px;
  text-align: left !important;
  height: 135px !important;
}

.util-detail-timeline-container {
  background: var(--sidebarColor);
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
}

.util-detail-history-container {
  background: var(--sidebarColor);
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  margin-left: 10px;
}

.info-container {
  background: var(--sidebarColor);
  border-radius: 5px;
  padding: 5px !important;
  margin: 5px !important;
}

.user-machine-info-container-mobile {
  height: 100px;
}

.machine-detail-user-info-mobile {
  background: var(--sidebarColor);
  border-radius: 5px;
  padding: 8px 20px !important;
  margin: 5px !important;
  height: 80px;
  text-align: center;
}

.operator-name-style {
  color: #eeeeee;
  text-align: left;
  margin-top: 17px;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  float: left;
}

.user-name-container {
  /* display: flex;
    flex-direction: row;
    width: 50%; */
  float: center;
}

.app-version-container {
  /* display: flex;
    flex-direction: row;
    width: 50%; */
  float: right;
}

.app-version-text {
  color: #eeeeee;
  text-align: left;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
}

/* style={{ display: 'flex', flexDirection: 'row', width: '100%' }} */
.detail-operator-image-style {
  margin-top: 5px;
  float: left;
  border-radius: 50%;
  box-shadow: 0px 0px 5px white;
  object-fit: cover;
}

.machine-image-container {
  text-align: center !important;
}

.machine-image-container-mobile img {
  max-width: 100%;
  height: 90px !important;
}

.intro-video-container {
  height: 152px !important;
  padding-top: 25px;
}


.utilization-video-container {
  height: 152px !important;
  padding-top: 25px;
}

.indicator-container {
  height: 318px !important;
  padding: 10px !important;
}

.utilization-detail-container {
  padding-top: 0px !important;
}

.timeline-container {
  height: 223px !important;
}

.util-history-container {
  height: 100%;
}

.history-item {
  color: #eeeeee !important;
  padding: 0px !important;
  margin: 0px !important;
}

.history-item:hover {
  cursor: pointer;
  background: #8a8a8a !important;
}

.history-item:active {
  cursor: pointer;
  background: rgb(104, 104, 104) !important;
}

.history-item-selected {
  background-color: #1e1e1e !important;
  color: #ffffff !important;
}

.ant-progress-text {
  color: #eeeeee !important;
  font-weight: bold !important;
}

.ant-progress-circle>path {
  stroke-width: 20 !important;
}

.see-detail-gantt-modal {
  color: #36a3f0 !important;
  cursor: pointer;
}

.see-detail-gantt-modal:hover {
  color: rgb(94, 185, 250) !important;
}

.detailed-line-timeline text {
  color: #eeeeee !important;
  fill: #eeeeee !important;
}

.detailed-line-timeline svg g:first-of-type rect {
  fill-opacity: 0;
}

.detail-gantt-modal {
  background: #1e1e1e !important;
  background-color: #1e1e1e !important;
}

.detail-gantt-modal .ant-modal-body {
  background: #1e1e1e !important;
  background-color: #1e1e1e !important;
  border: 2px solid white !important;
}

.detailed-chart-container {
  width: 100% !important;
  height: 100% !important;
}

.detail-gantt-modal .ant-modal-close-x {
  display: none !important;
}

.backward-button {
  color: #eeeeee !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.backward-button :hover {
  color: #f5dbdb !important;
  border: 1px solid #1e1e1e !important;
}

.app-setting-button {
  color: #eeeeee !important;
  font-size: 22px !important;
  font-weight: bold !important;
  margin: 6%;
  text-align: right !important;
}

.app-setting-show_cycle_help {
  color: #eeeeee !important;
  font-size: 30px !important;
  font-weight: bold !important;
  margin: 2%;
}

.app-setting-button:hover {
  color: #f5dbdb !important;
  border: 1px solid #1e1e1e !important;
}

.sendEmail {
  font-size: 30px !important;
  color: grey !important;
  rotate: 10 !important;
  transform: rotate(-20deg);
}

.sendEmail:hover {
  color: white !important;
  transform: rotate(-45deg);
}

.sendEmail:active {
  color: red !important;
  transform: rotate(-45deg);
}

.sendEmailContainer {
  text-align: right !important;
  padding-right: 50px !important;
}

.confirm-dialog>.ant-modal-content {
  background-color: #0e0e0e !important;
}

.confirm-dialog>* .ant-modal-confirm-title {
  color: #eeeeee !important;
}

.confirm-dialog>* .ant-modal-confirm-content {
  color: #eeeeee !important;
  font-size: 20px !important;
  text-align: center;
}

.confirm-dialog>* .ant-btn-primary {
  color: green !important;
  border-color: #0e0e0e !important;
}

.confirm-dialog>* .ant-btn-primary:hover {
  color: darkgreen !important;
  border-color: green !important;
}

.confirm-dialog>* .ant-btn {
  color: red;
  border-color: #0e0e0e;
  background-color: #0e0e0e !important;
}

.confirm-dialog>* .ant-btn:hover {
  color: rgb(243, 75, 75);
  border-color: #0e0e0e;
  background-color: #0e0e0e !important;
}


.machine-detail-oee-container {
  height: 170px !important;
  padding: 0px !important;
  margin-bottom: 10px !important;
}

.machine-detail-gauge-container {
  height: 120px !important;
  padding: 0px !important;
  margin-bottom: 10px !important;
}

.indicator-title {
  font-size: 15px;
  font-weight: bold;
  color: white;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 3px;
  text-align: left;
}


.utilization-detail-machine-detail-container {
  background: transparent;
  margin: 15px 10px;
  text-align: center !important;
}

.utilization-detail-chart-container{
  padding: 10px 15px 0px 15px;
}

.utilization-detail-chart-title{
  font-size: 20px;
  color: #eeeeee;
  font-weight: bold;
  text-align: left;
}

.utilization-detail-chart-subtitle{
  font-size: 12px;
  color: #eeeeee;
  text-align: left;
}

.utilization-detail-chart-left {
  display: flex;
  flex-wrap: wrap;
  align-content:center;
}

.utilization-detail-chart-hrs {
  text-align: left;
  padding-top: 5px;
  font-size: 30px;
  font-weight: bold;
  color: #eeeeee;
}

.utilization-detail-chart-percentage {
  text-align: left;
  font-size: 14px;
  background-color: #74c569;
  border-radius: 15px;
  text-align: center;
  width: 80px;
  height: 30px;
  line-height: 30px;
  margin-top: 5px;
  color: #000000;
  font-weight: bold;
}

.utilization-detail-chart-right {
  padding: 0px;
}

.utilization-detail-chart-weekly-pie {
  margin-top: 30px;
  padding: 0px;
  max-height: 300px !important;
  height: 300px !important;
}

.utilization-detail-chart-timeline {
  width: 100%;
  align-content:center;
  padding: 0px;
  height: 193px;
}

.utilization-detail-status-left {
  padding-top: 10px;
  text-align: left;
  height: 190px;
}

.utilization-detail-status-title {
  font-size: 32px;
  font-weight: bold;
}

.utilization-detail-status-subtitle {
  color: var(--whiteColor);
  font-size: 16px;
  margin-top: 3px;
}
