.one-email-container-style {
    margin-right: 10px;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.151);
    padding: 5px 10px 5px 10px;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 14px;
}



.one-email-style {
    color: #eeeeee;
    margin-right: 30px;
    display: inline;
    height: 40px !important;
}


.one-email-delete-style {
    color: #ff0000;
    cursor: pointer;
    display: inline;
}

.one-email-delete-style:hover {
    font-weight: bold;
    color: #fd2f2f;
}

.one-email-delete-style:active {
    color: #ce0303;
}