.machine-hst-preview-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 21px;
  color: black;
  margin-left: 20px;
}

.machine-hst-gragh-preview-back {
  margin-left: 20px;
  margin-right: 20px;
  /* height: 150px; */
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  background: linear-gradient(#313131, #1b1b1b);
}
